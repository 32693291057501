<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('irriWaterTest.testing_payment_report') }}</h4>
      </template>
      <template v-slot:body>
      <b-row>
        <b-col xl="6" lg="6" md="6" sm="12" xs="12">
          <b-form-group
            class="row"
            label-cols-sm="3"
            label-for="org_id"
            >
            <template v-slot:label>
              {{ $t('org_pro.organization') }} <span class="text-danger">*</span>
              </template>
            <b-form-select
              plain
              v-model="search.org_id"
              :options="organizationList"
              id="org_id"
              >
              <template v-slot:first>
                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
              </template>
            </b-form-select>
            </b-form-group>
        </b-col>
        <b-col xl="6" lg="6" md="6" sm="12" xs="12">
          <b-form-group
            class="row"
            label-cols-sm="3"
            :label="$t('irriWaterTest.laboratory')"
            label-for="laboratory_id"
            >
            <b-form-select
              plain
              v-model="search.laboratory_id"
              :options="laboratoryList"
              id="laboratory_id"
              >
              <template v-slot:first>
                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
              </template>
            </b-form-select>
            </b-form-group>
        </b-col>

        <b-col xl="6" lg="6" md="6" sm="12" xs="12">
          <b-form-group
            class="row"
            label-cols-sm="3"
            :label="$t('farmerOperator.testing_type')"
            label-for="testing_type_id"
            >
            <b-form-select
              plain
              v-model="search.testing_type_id"
              :options="testingTypeList"
              id="testing_type_id"
              >
              <template v-slot:first>
                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
              </template>
            </b-form-select>
            </b-form-group>
        </b-col>
        <b-col xl="6" lg="6" md="6" sm="12" xs="12">
          <b-form-group
            class="row"
            label-cols-sm="3"
            :label="$t('globalTrans.from_date')"
            label-for="from_date"
          >
            <b-form-input
              class="date-picker"
                v-model="search.from_date"
              :placeholder="$t('globalTrans.select_date')"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col xl="6" lg="6" md="6" sm="12" xs="12">
          <b-form-group
            class="row"
            label-cols-sm="3"
            :label="$t('globalTrans.to_date')"
            label-for="to_date"
          >
            <b-form-input
              class="date-picker"
                v-model="search.to_date"
              :placeholder="$t('globalTrans.select_date')"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6">
          <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
        </b-col>
      </b-row>
      </template>
    </iq-card>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('irriWaterTest.testing_payment_report_list') }}</h4>
          </template>
          <template v-slot:headerAction>
            <b-button class="btn_add_new mr-2" @click="pdfExport">
              <i class="fas fa-print"></i>{{  $t('globalTrans.print') }}
            </b-button>
            <export-excel
              class="btn btn_add_new"
              :data="listData"
              :fields= "json_fields"
              :title="$t('irriPumpInformation.Pump_Information_Report')"
              worksheet="$t('irriPumpInformation.Pump_Information_Report')"
              name="Pump-Information-Report.xls">
              <i class="far fa-file-excel"></i>{{ $t('globalTrans.export_excel') }}
            </export-excel>
          </template>
          <template v-slot:body>
            <b-overlay :show="loading">
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table thead-class="table_head" bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + 1) }}
                    </template>
                    <template v-slot:cell(address_bn)="data">
                      {{ data.item.far_village_bn + ', ' + data.item.union_name_bn + ', ' + data.item.upazilla_name_bn + ', ' + data.item.district_name_bn + ', ' + data.item.division_name_bn }}
                    </template>
                    <template v-slot:cell(address)="data">
                      {{ data.item.far_village + ', ' + data.item.union_name + ', ' + data.item.upazilla_name + ', ' + data.item.district_name + ', ' + data.item.division_name }}
                    </template>
                    <template v-slot:cell(amount)="data">
                      {{ $n(data.item.amount) }}
                    </template>
                    <template v-slot:cell(applicant_name)="data">
                      {{ currentLocale === 'bn' ? data.item.name_bn : data.item.name }}
                    </template>
                    <template v-slot:cell(created_at)="data">
                      {{ data.item.updated_at | dateFormat }}
                    </template>
                  </b-table>
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import { waterTestingPaymentReport } from '../../api/routes'
import RestApi, { irriSchemeServiceBaseUrl } from '../../../../../config/api_config'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import flatpickr from 'flatpickr'
import HierarchycalNames from '@/Utils/common'
import excel from 'vue-excel-export'
import Vue from 'vue'
import ExportPdf from '@/Utils/export-pdf'
import { mapGetters } from 'vuex'
Vue.use(excel)
const excelColumn = {
  'Testing Type': 'testing_type_name',
  'Applicant Name': 'name',
  Address: 'area',
  'laboratory Name': 'laboratory_name',
  Amount: 'amount',
  'Payment Date': 'created_at'
}
const excelColumnBn = {
  'Testing Type': 'testing_type_name_bn',
  'Applicant Name': 'name_bn',
  Address: 'area',
  'laboratory Name': 'laboratory_name_bn',
  Amount: 'amount',
  'Payment Date': 'created_at'
}

export default {
  mixins: [ModalBaseMasterList],
  components: {
  },
  data () {
    return {
      loading: false,
      search: {
        org_id: '0',
        laboratory_id: '0',
        testing_type_id: '0',
        from_date: '',
        to_date: ''
      },
      rows: [],
      item: '',
      laboratoryList: []
    }
  },
  computed: {
     ...mapGetters({
      authUser: 'Auth/authUser'
    }),
    currentLocale () {
      return this.$i18n.locale
    },
    organizationList: function () {
      return this.$store.state.commonObj.organizationProfileList
    },
    testingTypeList: function () {
      const ObjectData = this.$store.state.commonObj.testingTypeList
      return ObjectData.map((obj, index) => {
          if (this.$i18n.locale === 'bn') {
            return { value: obj.id, text: obj.name_bn }
          } else {
            return { value: obj.id, text: obj.name }
          }
        })
    },
    waterTestingStatusList: function () {
      const ObjectData = this.$store.state.commonObj.waterTestingStatusList
      return ObjectData.map((obj, index) => {
          if (this.$i18n.locale === 'bn') {
            return { value: obj.id, text: obj.name_bn }
          } else {
            return { value: obj.id, text: obj.name }
          }
        })
    },
    detailTitle () {
       return this.$t('farmerOperator.water_testing_request_detail')
    },
    json_fields: function () {
      return this.$i18n.locale === 'bn' ? excelColumnBn : excelColumn
    },
    dataCustomize () {
      const listContractor = this.listData.map(item => {
        return Object.assign({}, item, {
          area: this.$i18n.locale === 'en' ? item.area_en : item.area_bn,
          pump_id: this.$n(item.pump_id, { useGrouping: false }),
          status: item.status ? this.$t('globalTrans.inactive') : this.$t('globalTrans.active')
        })
      })
      return listContractor
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
          { label: this.$t('irriWaterTest.testing_type'), class: 'text-center' },
          { label: this.$t('farmerOperator.applicant_name'), class: 'text-left' },
          { label: this.$t('globalTrans.address'), class: 'text-center' },
          { label: this.$t('irriWaterTest.responsible_lab'), class: 'text-left' },
          { label: this.$t('irriWaterTest.amount'), class: 'text-left' },
          { label: this.$t('irriWaterTest.payment_date'), class: 'text-left' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'testing_type_name_bn' },
          { key: 'applicant_name' },
          { key: 'address_bn' },
          { key: 'laboratory_name_bn' },
          { key: 'amount' },
          { key: 'created_at' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'testing_type_name' },
          { key: 'applicant_name' },
          { key: 'address' },
          { key: 'laboratory_name' },
          { key: 'amount' },
          { key: 'created_at' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
        })
    }
  },
  watch: {
    'search.org_id': function (newVal, oldVal) {
      this.getLaboratoryList(newVal)
    },
    currentLocale: function (newVal, oldVal) {
        if (newVal !== oldVal) {
            if (this.search.org_id) {
              this.getLaboratoryList(this.search.org_id)
            }
        }
    }
  },
  mounted () {
    flatpickr('.date-picker', {})
    core.index()
    if (this.authUser.role_id === 0 && this.authUser.is_org_admin === 1) {
      this.search = Object.assign({}, this.search, {
        org_id: this.authUser.org_id
      })
    } else if (this.authUser.role_id === 0) {
      this.search = Object.assign({}, this.search, {
        org_id: this.authUser.office_detail.org_id
      })
    }
  },
  methods: {
    details (item) {
      this.item = item
    },
    downloadAttachment (item) {
      var fileURL = irriSchemeServiceBaseUrl + 'storage/uploads/water-test-reports/original/' + item.attachment
      window.open(fileURL)
    },
    pdfExport () {
      const rowData = this.getPdfData()
      const labels = [
          { text: this.$t('globalTrans.sl_no'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('irriWaterTest.testing_type'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('farmerOperator.applicant_name'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('globalTrans.address'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('irriWaterTest.responsible_lab'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('irriWaterTest.amount'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('irriWaterTest.payment_date'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) }
        ]
      rowData.unshift(labels)
      const reportTitle = this.$i18n.locale === 'en' ? 'Pump Report' : 'পাম্প রিপোর্ট'
      const columnWids = ['7%', '15%', '15%', '25%', '15%', '10%', '16%']
      ExportPdf.exportPdf(irriSchemeServiceBaseUrl, '/report-heading/detail', this.search.org_id, reportTitle, rowData, columnWids)
    },
    getPdfData () {
      let keys = []
      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'serial_no' },
          { key: 'testing_type_name_bn' },
          { key: 'name_bn' },
          { key: 'operator_location' },
          { key: 'laboratory_name_bn' },
          { key: 'amount' },
          { key: 'created_at' }
        ]
      } else {
        keys = [
          { key: 'serial_no' },
          { key: 'testing_type_name' },
          { key: 'name' },
          { key: 'operator_location' },
          { key: 'laboratory_name' },
          { key: 'amount' },
          { key: 'created_at' }
        ]
      }
      var serial = 0
      return this.listData.map(item => {
          const allNames = HierarchycalNames.getCommonHierarchyNames(item.far_union_id, 'union')
          serial += 1
          const rowData = keys.map(keyItem => {
            if (keyItem.key === 'operator_location') {
                return { text: `${allNames.divisionName}, ${allNames.districtName}, ${allNames.upazilaName}, ${allNames.unionName}` }
            }

            if (keyItem.key === 'serial_no') {
                return { text: this.$n(serial) }
            }
            if (keyItem.key === 'status') {
              return { text: item.status ? this.$t('globalTrans.inactive') : this.$t('globalTrans.active') }
            }
            if (keyItem.key === 'pump_id') {
              return { text: this.$n(item.pump_id, { useGrouping: false }) }
            }
            return { text: item[keyItem.key] }
        })

        return rowData
      })
    },
    default () {
      return {
        id: this.rows.length,
        division_id: '',
        district_id: '',
        upazilla_id: '',
        waterTestingRequest_name: '',
        waterTestingRequest_name_bn: ''
      }
    },
    searchData () {
      if (this.authUser.role_id === 0 && this.authUser.is_org_admin === 1) {
        this.search = Object.assign({}, this.search, {
          org_id: this.authUser.org_id
        })
      } else if (this.authUser.role_id === 0) {
        this.search = Object.assign({}, this.search, {
          org_id: this.authUser.office_detail.org_id
        })
      }
      this.loadData()
    },
    loadData () {
      this.loading = true
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(irriSchemeServiceBaseUrl, waterTestingPaymentReport, params).then(response => {
        this.loading = false
        if (response.success) {
          this.$store.dispatch('setList', this.getRelationalData(response.data))
          this.paginationData(response.data)
        } else {
          this.$store.dispatch('setList', [])
        }
      })
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    getRelationalData (data) {
      const organizationList = this.$store.state.orgList
      const divisionList = this.$store.state.commonObj.divisionList
      const districtList = this.$store.state.commonObj.districtList
      const upazilaList = this.$store.state.commonObj.upazilaList
      const unionList = this.$store.state.commonObj.unionList
      const testingTypeList = this.$store.state.commonObj.testingTypeList
      const waterTestingStatusList = this.$store.state.commonObj.waterTestingStatusList
      return data.map(item => {
        const orgObject = organizationList.find(oganization => oganization.value === item.org_id)
        const divisionObject = divisionList.find(division => division.value === item.far_division_id)
        const districtObject = districtList.find(district => district.value === item.far_district_id)
        const upazilaObject = upazilaList.find(upazilla => upazilla.value === item.far_upazilla_id)
        const unionObject = unionList.find(union => union.value === item.far_union_id)

        const testingTypeObject = testingTypeList.find(testingType => testingType.id === item.testing_type_id)
        const statusListObject = waterTestingStatusList.find(testingStatus => testingStatus.id === item.status)
        const orgData = {
          org_name: typeof orgObject !== 'undefined' ? orgObject.text_en : '',
          org_name_bn: typeof orgObject !== 'undefined' ? orgObject.text_bn : ''
        }
        const divisionData = {
          division_name: typeof divisionObject !== 'undefined' ? divisionObject.text_en : '',
          division_name_bn: typeof divisionObject !== 'undefined' ? divisionObject.text_bn : ''
        }
        const districtData = {
          district_name: typeof districtObject !== 'undefined' ? districtObject.text_en : '',
          district_name_bn: typeof districtObject !== 'undefined' ? districtObject.text_bn : ''
        }
        const upazillaData = {
          upazilla_name: typeof upazilaObject !== 'undefined' ? upazilaObject.text_en : '',
          upazilla_name_bn: typeof upazilaObject !== 'undefined' ? upazilaObject.text_bn : ''
        }
        const unionData = {
          union_name: typeof unionObject !== 'undefined' ? unionObject.text_en : '',
          union_name_bn: typeof unionObject !== 'undefined' ? unionObject.text_bn : ''
        }
        const TestingTypeData = {
          testing_type_name: typeof testingTypeObject !== 'undefined' ? testingTypeObject.name : '',
          testing_type_name_bn: typeof testingTypeObject !== 'undefined' ? testingTypeObject.name_bn : ''
        }
        const waterTestingData = {
          testing_status_name: typeof statusListObject !== 'undefined' ? statusListObject.name : '',
          testing_status_name_bn: typeof statusListObject !== 'undefined' ? statusListObject.name_bn : ''
        }
        return Object.assign({}, item, orgData, divisionData, districtData, upazillaData, unionData, TestingTypeData, waterTestingData)
      })
    },
    getLaboratoryList (orgId) {
      const labList = this.$store.state.IrriConfig.commonObj.laboratoryList.filter(item => item.status === 0 && item.org_id === orgId)
        this.laboratoryList = labList.map((obj, index) => {
          if (this.$i18n.locale === 'bn') {
            return { value: obj.value, text: obj.text_bn }
          } else {
            return { value: obj.value, text: obj.text }
          }
        })
    }
  }
}
</script>
